@mixin expert-section {
  margin-bottom: 1rem;
  .content {
    box-sizing: border-box;
  }
  .col-form-label {
    margin-left: 5%;
  }
  .form-control {
    height: 30px;
  }
  .foot-note {
    color: grey;
    margin-top: 3%;
    padding: 1%;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    .col-form-label {
      margin-left: 0;
    }
    .hourly-rate-section {
      padding: 0 !important;
    }
  }
}

#general-section {
  width: 100%;
  margin-bottom: 5rem;
  .content {
    box-sizing: border-box;
  }
  .ui.selection.dropdown {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


#professional-section {
  margin-bottom: 5rem;
  @include expert-section;
  #iv-hourly-rate-message {
    margin: 2rem 0 2.5rem 0;
    .content {
      margin-left: 0;
    }
    #iv-hourly-rate-message-icon {
      font-size: 24px;
      padding-right: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    #hourly_rate_field {
      width: 100% !important;
    }
  
    #iv-hourly-rate-message {
      font-size: 14px;
      padding: 10px;
    }
  
    #iv-hourly-rate-message-icon {
      font-size: 20px;
    }
  }
}

#expertise-section {
  margin-bottom: 5rem;
  @include expert-section;
  ul {
    margin-top: 10px;
    list-style: none;
    padding-inline-start: 0 !important;
  }
  .default-tags {
    background: none !important;
    border: 1px solid lightgrey !important;
    color: #333 !important;
  }
  #tags-add-btn {
    background: #36ac91 !important;
    margin: 5px;
  }
  .ui.selection.dropdown {
    padding: 5px !important;
  }
  .field {
    margin-bottom: 5% !important;
  }
  .ui[class*='right labeled'].input > input {
    border-right-color: lightgrey !important;
  }
  .customize-tags {
    color: rgba(0, 0, 0, 0.87) !important;
    border: none !important;
    padding: 0.35714286em 0.78571429em;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
    font-size: 14px !important;
  }
  .save-btn {
    margin-bottom: 0px !important;
  }
  .rdw-editor-main {
    color: black;
    border: 1px solid rgb(222, 222, 222);
    height: 200px;
  }
}

#eeo-section {
  @include expert-section;
  .ui.dropdown > .text,
  .ui.dropdown:not(.button) > .default.text {
    padding: 1% 0 0 1% !important;
    font-size: 15px !important;
  }
}

.ui.form {
  position: static;
}

.ui.pointing.label {
  display: inline-block !important;
}
